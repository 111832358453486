// import PortalMenu from "../components/PortalMenu/PortalMenu";
import ProductsListPage from "./ProductsListPage";

const PriceList = () => {
  return (
    <div className="main">
      {/* <div className="home-sidebar">
        <PortalMenu />
      </div> */}
      {/* <div className="portal-content"> */}
      <ProductsListPage type={"pricelist"} />
      {/* </div> */}
    </div>
  );
};

export default PriceList;
