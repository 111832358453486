import { useEffect, useState } from "react";
import css from "./ProductCard.module.css";
import SelectFromList from "../control/SelectFromList/SelectFromList";
import CustomInput from "../control/CustomInput/CustomInput";
import CustomSelect from "../control/CustomSelect/CustomSelect";
import ButtonSave from "../control/ButtonSave/ButtonSave";
import ButtonClose from "../control/ButtonClose/ButtonClose";

const AddPricesBtn = ({ name, id, priceList, setPriceList }) => {
  const [addOpen, setAddOpen] = useState(false);
  const [vendor, setVendor] = useState("");
  const [vendorID, setVendorID] = useState(false);
  const [code, setCode] = useState("");
  const [price, setPrice] = useState(0);
  const [available, setAvailable] = useState(1);
  const [newData, setNewData] = useState({});

  useEffect(() => {
    setNewData({
      productID: id,
      vendor: vendor['name'],
      vendor_code: code,
      price: price,
      available: available,
    });
  }, [id, vendor, code, price, available]);

  return (
    <>
      <div
        className={css["modal-edit-add-price"]}
        onClick={() => {
          setAddOpen(true);
        }}
      >
        додати ціну
      </div>
      {addOpen && (
        <div className={css["modal-edit-prices-container"]}>
          <div className={css["modal-add-prices-content"]}>
            <div className={css["modal-header-container"]}>
              <div className={css["modal-header-title"]}>
                Додати нову ціну товару: {name}
              </div>
              <div
                className={css["modal-header-close-btn"]}
                onClick={() => {
                  setAddOpen(false);
                }}
              >
                <svg width="20" height="20">
                  <use xlinkHref="#svg-icon__close" />
                </svg>
              </div>
            </div>
            <div className={css["modal-add-prices-form-container"]}>
              <SelectFromList
                requestPath={"/sources/get_source/"}
                label={"Постачальник:"}
                value={vendor}
                setValue={setVendor}
                valueID={vendorID}
                setValueID={setVendorID}
              />
              <CustomInput value={code} setValue={setCode} label={"Код:"} />
              <CustomInput value={price} setValue={setPrice} label={"Ціна:"} type = {'number'} />
              <CustomSelect
                value={available}
                setValue={setAvailable}
                valueList={[
                  { id: 0, name: "немає" },
                  { id: 1, name: "є" },
                ]}
                label={"Доступність:"}
              />
              <div style={{ display: "flex" }}>
                <div onClick={() => {
                        setPriceList([...priceList, {"vendor": vendor['name'], "vendor_code": code, "price": Number(price), "quantity": null, "available": available, "blocked": 0}])
                        setAddOpen(false);
                      }}>
                  <ButtonSave
                      data={newData}
                      redirect={""}
                      requestPath={"/catalog/create_price/"}
                    />
                </div>
                <div onClick={() => {
                        setAddOpen(false);
                      }}>
                  <ButtonClose 
                    redirect={""} 
                    onClick={() => {
                      setAddOpen(false);
                    }}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddPricesBtn;
