import { useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useContext } from "react";
import userStatusContext from "../../context/userStatusContext";
import { IconContext } from "react-icons";
import {
  CgMenu,
  CgMenuGridR,
  CgArrowLongDown,
  CgArrowLongUp,
} from "react-icons/cg";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import apiaxios from "../../common/apiaxios";
import css from "./ProductsList.module.css";
import ProductListPagination from "./ProductListPagination";
import ProductCard from "../ProductCard/ProductCard";
import { Circles } from "react-loader-spinner";
import SelectVendor from "./SelectVendor";
import { useSelector } from "react-redux";
import SelectPriceScheme from "./SelectPriceScheme";
import { selectBasketPriceScheme } from "../../redux/basketSelectors";

const ProductsList = ({
  setIsOpen,
  filtersSearchAll,
  setFiltersSearchAll,
  catalogNavigate,
  type,
}) => {
  const [searchParams] = useSearchParams();
  const { categoryID } = useParams();
  const userStatus = useSelector((state) => state.user.status);
  const priceScheme = useSelector(selectBasketPriceScheme);
  const {
    filtersLabels,
    setFiltersLabels,
    sortTypePrice,
    setSortTypePrice,
    search,
    setSearch,
    filters,
    setFilters,
    page,
    setPage,
    perPage,
    setPerPage,
    selectVendor,
    vendorBest,
    minPrice,
    setMinPrice,
    maxPrice,
    setMaxPrice,
  } = useContext(userStatusContext);
  const [iconLineView, setIconLineView] = useState(
    JSON.parse(localStorage.getItem("iconLine"))
      ? JSON.parse(localStorage.getItem("iconLine"))
      : "icon"
  );
  const [list, setList] = useState([]);
  const [priceListGroups, setPriceListGroups] = useState([]);
  const [listCount, setListCount] = useState(0);
  const [group, setGroup] = useState("");
  const [circle, setCircle] = useState(true);
  const [showPrice, setShowPrice] = useState(
    JSON.parse(localStorage.getItem("showPrice"))
  );
  let path = "";
  let xit = false;
  let filtersPath = "";
  if (searchParams.get("filters")) {
    filtersPath = searchParams.get("filters");
  }
  if (searchParams.get("search") || categoryID) {
    path =
      "/catalog/get_products_list?perPage=" +
      perPage +
      "&page=" +
      page +
      "&category=" +
      categoryID +
      "&search=" +
      searchParams.get("search") +
      "&sort=" +
      sortTypePrice +
      "&filters=" +
      filtersPath +
      "&gt=" +
      minPrice +
      "&lt=" +
      maxPrice +
      "&price_scheme=" +
      priceScheme +
      "&xit=0";
  } else {
    if (type === "xit") {
      path =
        "/catalog/get_products_list?perPage=" +
        perPage +
        "&page=" +
        page +
        "&search=" +
        searchParams.get("search") +
        "&sort=" +
        sortTypePrice +
        "&filters=" +
        filtersPath +
        "&gt=" +
        0 +
        "&lt=" +
        maxPrice +
        "&price_scheme=" +
        priceScheme +
        "&xit=1";
      xit = true;
    } else {
      path =
        "/catalog/get_products_list?perPage=" +
        perPage +
        "&page=" +
        page +
        "&search=" +
        searchParams.get("search") +
        "&sort=" +
        sortTypePrice +
        "&filters=" +
        filtersPath +
        "&gt=" +
        0 +
        "&lt=" +
        maxPrice +
        "&price_scheme=" +
        priceScheme +
        "&xit=0";
      xit = true;
    }
  }

  useEffect(() => {
    setPage(Number(searchParams.get("page")));
    setPerPage(searchParams.get("perPage") ? searchParams.get("perPage") : 20);
    setSortTypePrice(
      searchParams.get("sort") ? searchParams.get("sort") : "none"
    );
    setFilters(
      searchParams.get("filters") ? searchParams.get("filters").split("|") : []
    );
    setSearch(searchParams.get("search") ? searchParams.get("search") : "");
    setMinPrice(searchParams.get("gt") ? Number(searchParams.get("gt")) : 0);
    setMaxPrice(
      searchParams.get("lt") ? Number(searchParams.get("lt")) : 1000000
    );
    setCircle(true);
    apiaxios
      .get(path, {
        params: {
          vendor: selectVendor,
          best: vendorBest,
        },
      })
      .then((res) => {
        setList(res.data["list"]);
        setListCount(res.data["listCount"]);
        setGroup(res.data["group"]);
        if (xit === false || type === "pricelist") {
          setFiltersLabels(res.data["filters"]);
          setFiltersSearchAll(res.data["filtersSearchAll"]);
          if (res.data["filters"].length>0) {
            setPriceListGroups(res.data["filters"])
          } else {
            setPriceListGroups(res.data["filtersSearchAll"])
          }
        }
        setCircle(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [
    searchParams,
    setPage,
    setPerPage,
    setSortTypePrice,
    setFilters,
    setSearch,
    path,
    setFiltersLabels,
    setFiltersSearchAll,
    setMinPrice,
    setMaxPrice,
    xit,
    type,
    selectVendor,
    priceScheme,
    vendorBest,
  ]);

  useEffect(() => {
    // Після успішного оновлення виконайте прокручування до верху сторінки
    window.scrollTo({ top: 0, behavior: "smooth" }); // Прокручуємо до верху сторінки з анімацією
  }, [list]);

  const handleChange = (e) => {
    setPerPage(e.target.value);
    localStorage.setItem("productsPerPage", e.target.value);
    catalogNavigate(
      filters,
      e.target.value,
      1,
      sortTypePrice,
      search,
      minPrice,
      maxPrice
    );
    setPage(1);
  };

  const sortByPrice = () => {
    if (sortTypePrice === "none") {
      setSortTypePrice("priceup");
      catalogNavigate(
        filters,
        perPage,
        1,
        "priceup",
        search,
        minPrice,
        maxPrice
      );
    } else if (sortTypePrice === "priceup") {
      setSortTypePrice("pricedown");
      catalogNavigate(
        filters,
        perPage,
        1,
        "pricedown",
        search,
        minPrice,
        maxPrice
      );
    } else if (sortTypePrice === "pricedown") {
      setSortTypePrice("none");
      catalogNavigate(filters, perPage, 1, "none", search, minPrice, maxPrice);
    }
    setPage(1);
  };

  const clickFilterX = (e) => {
    const newList = [...filters];

    if (newList.includes(e.currentTarget.id)) {
      newList[newList.indexOf(e.currentTarget.id)] = "";
    } else {
      newList.push(e.currentTarget.id);
    }

    const newListAfter = [];
    for (const fil of newList) {
      if (fil) {
        newListAfter.push(fil);
      }
    }
    newListAfter.sort();
    catalogNavigate(
      newListAfter,
      perPage,
      1,
      sortTypePrice,
      search,
      minPrice,
      maxPrice
    );
    setFilters(newListAfter);
    setPage(1);
  };

  return (
    <>
      {circle && (
        <div className="spinner">
          <Circles
            height="150"
            width="150"
            color="rgb(50, 130, 250)"
            ariaLabel="circles-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
      )}
      {!circle && (
        <div className={css["product-list"]}>
          <div className={css["header-product-list"]}>
            <div className={css["header-product-list-title-count-block"]}>
              <div className={css["header-product-list-title"]}>{group}</div>
              <div className={css["header-product-list-count"]}>
                {listCount}
              </div>
              {type !== "pricelist" && (
              <ProductListPagination
                listCount={listCount}
                catalogNavigate={catalogNavigate}
              />)}
            </div>

            <div className={css["header-product-list-options-block"]}>
              {type !== "xit" && (
                <div>
                  <span className={css["sort-text"]}>Сортувати:</span>
                  <span
                    className={
                      sortTypePrice === "none"
                        ? css["sort-item"]
                        : css["sort-item-selected"]
                    }
                    onClick={sortByPrice}
                  >
                    За ціною {sortTypePrice === "priceup" && <CgArrowLongUp />}{" "}
                    {sortTypePrice === "pricedown" && <CgArrowLongDown />}
                  </span>
                </div>
              )}
              <div className={css["select-btn-container"]}>
                {type !== "xit" && (
                  <>
                    <div
                      className={css["select-btn-filter"]}
                      onClick={() => {
                        setIsOpen(true);
                      }}
                    >
                      Фільтри
                    </div>
                    <div className={css["select-btn-sort-block"]}>
                      <div
                        className={css["select-btn-filter"]}
                        onClick={sortByPrice}
                      >
                        За ціною{" "}
                        {sortTypePrice === "priceup" && <CgArrowLongUp />}{" "}
                        {sortTypePrice === "pricedown" && <CgArrowLongDown />}
                      </div>
                    </div>
                  </>
                )}

                {type !== "pricelist" && (
                <div>
                  <label className={css["select-label"]}>На сторінці:</label>
                  <select
                    name="pages"
                    value={perPage}
                    onChange={handleChange}
                    className={css["select"]}
                  >
                    <option value="20">20</option>
                    <option value="40">40</option>
                    <option value="100">100</option>
                    <option value="10000">усі</option>
                  </select>
                </div>
                )}

                {(userStatus === "super" || userStatus === "staff") && (
                  <>
                    <div
                      className={css["price-eye"]}
                      onClick={() => {
                        setShowPrice(!showPrice);
                        localStorage.setItem(
                          "showPrice",
                          JSON.stringify(!showPrice)
                        );
                      }}
                    >
                      <IconContext.Provider value={{ size: "1.5em" }}>
                        {showPrice ? <FaRegEye /> : <FaRegEyeSlash />}
                      </IconContext.Provider>
                    </div>
                    <div className={css["select-vendor"]}>
                      <SelectPriceScheme />
                    </div>
                    {type !== "pricelist" && (
                      <div className={css["select-vendor"]}>
                        <SelectVendor />
                      </div>
                    )}
                  </>
                )}
                <div
                  className={
                    iconLineView === "icon"
                      ? css["icon-line-btn-active"]
                      : css["icon-line-btn"]
                  }
                  onClick={() => {
                    setIconLineView("icon");
                    localStorage.setItem("iconLine", JSON.stringify("icon"));
                  }}
                >
                  <IconContext.Provider value={{ size: "1.2em" }}>
                    <div id="login">
                      <CgMenuGridR />
                    </div>
                  </IconContext.Provider>
                </div>
                <div
                  className={
                    iconLineView === "line"
                      ? css["icon-line-btn-active"]
                      : css["icon-line-btn"]
                  }
                  onClick={() => {
                    setIconLineView("line");
                    localStorage.setItem("iconLine", JSON.stringify("line"));
                  }}
                >
                  <IconContext.Provider value={{ size: "1.2em" }}>
                    <div id="login">
                      <CgMenu />
                    </div>
                  </IconContext.Provider>
                </div>
              </div>
            </div>
          </div>
          {filters.length > 0 && (
            <div className={css["header-product-list-select-filters-block"]}>
              {filtersLabels.map((filter) => (
                <div
                  className={css["header-product-list-select-filters-item"]}
                  key={filter.filterID}
                >
                  <Link
                    className={
                      css["header-product-list-select-filters-item-link"]
                    }
                    to={
                      "/products_list/" +
                      filter.filterID +
                      "?page=1&perPage=" +
                      perPage
                    }
                  >
                    {filter.filterName}
                  </Link>
                  <div
                    className={css["header-product-list-select-filters-item-x"]}
                    id={filter.filterID}
                    onClick={clickFilterX}
                  >
                    <svg width="10" height="10">
                      <use xlinkHref="#svg-icon__close" />
                    </svg>
                  </div>
                </div>
              ))}
            </div>
          )}
          {list.length !== 0 && type !== "pricelist" && (
            <>
              {iconLineView === "icon" && (
                <ul className={css["product-list-pag"]}>
                  {list &&
                    list.map((item) => (
                      <li key={item.id} className={css["product-list-wrap"]}>
                        <ProductCard
                          id={item.id}
                          name={item.name}
                          categoryID={item.categoryID}
                          onoff={item.onoff}
                          brief_description={item.brief_description}
                          date_modified={item.date_modified}
                          price_im={item.user_price}
                          price_max={item.price_im}
                          full_image={item.full_image}
                          favorite={item.favorite}
                          compare={item.compare}
                          prices={item.prices}
                          quantity={item.quantity}
                          sale_prices={item.sale_prices}
                          iconLineView={iconLineView}
                        />
                      </li>
                    ))}
                </ul>
              )}
              {iconLineView === "line" && (
                <ul width="100%">
                  {list &&
                    list.map((item) => (
                      <li key={item.id}>
                        <ProductCard
                          id={item.id}
                          name={item.name}
                          categoryID={item.categoryID}
                          onoff={item.onoff}
                          brief_description={item.brief_description}
                          date_modified={item.date_modified}
                          price_im={item.user_price}
                          price_max={item.price_im}
                          full_image={item.full_image}
                          favorite={item.favorite}
                          compare={item.compare}
                          prices={item.prices}
                          quantity={item.quantity}
                          sale_prices={item.sale_prices}
                          iconLineView={iconLineView}
                        />
                      </li>
                    ))}
                </ul>
              )}
            </>
          )}
          {list.length !== 0 && type === "pricelist" && (
            <>
              {iconLineView === "icon" && (
                <ul className={css["product-list-pag"]}>
                  {priceListGroups.map((elem) => (
                    <>
                      <div className={css["pricelist-block"]}>{elem.filterName}</div>
                      <ul className={css["product-list-pag"]}>
                        {list &&
                          list.map((item) => (
                            <>
                              {item.categoryID === elem.filterID && (
                                <li
                                  key={item.id}
                                  className={css["product-list-wrap"]}
                                >
                                  <ProductCard
                                    id={item.id}
                                    name={item.name}
                                    categoryID={item.categoryID}
                                    onoff={item.onoff}
                                    brief_description={item.brief_description}
                                    date_modified={item.date_modified}
                                    price_im={item.user_price}
                                    price_max={item.price_im}
                                    full_image={item.full_image}
                                    favorite={item.favorite}
                                    compare={item.compare}
                                    prices={item.prices}
                                    quantity={item.quantity}
                                    sale_prices={item.sale_prices}
                                    iconLineView={iconLineView}
                                  />
                                </li>
                              )}
                            </>
                          ))}
                      </ul>
                    </>
                  ))}
                </ul>
              )}
              {iconLineView === "line" && (
                <ul width="100%">
                  {priceListGroups.map((elem) => (
                    <>
                      <div className={css["pricelist-block"]}>{elem.filterName}</div>
                      <ul width="100%">
                        {list &&
                          list.map((item) => (
                            <>
                              {item.categoryID === elem.filterID && (
                                <li key={item.id}>
                                  <ProductCard
                                    id={item.id}
                                    name={item.name}
                                    categoryID={item.categoryID}
                                    onoff={item.onoff}
                                    brief_description={item.brief_description}
                                    date_modified={item.date_modified}
                                    price_im={item.user_price}
                                    price_max={item.price_im}
                                    full_image={item.full_image}
                                    favorite={item.favorite}
                                    compare={item.compare}
                                    prices={item.prices}
                                    quantity={item.quantity}
                                    sale_prices={item.sale_prices}
                                    iconLineView={iconLineView}
                                  />
                                </li>
                              )}
                            </>
                          ))}
                      </ul>
                    </>
                  ))}
                </ul>
              )}
            </>
          )}
          {list.length === 0 && (
            <div className={css["no-products-text"]}>
              Немає товарів за цим запитом...
            </div>
          )}
          <div className={css["pagination-footer-container"]}>
          {type !== "pricelist" && (
              <ProductListPagination
                listCount={listCount}
                catalogNavigate={catalogNavigate}
              />)}
          </div>
        </div>
      )}
      <div className={css["filter-sort-footer"]}>
        {!xit && (
          <>
            <div
              className={css["footer-btn"]}
              onClick={() => {
                setIsOpen(true);
              }}
            >
              Фільтри
            </div>
            <div
              className={css["footer-btn"]}
              onClick={() => {
                sortByPrice();
              }}
            >
              За ціною {sortTypePrice === "priceup" && <CgArrowLongUp />}{" "}
              {sortTypePrice === "pricedown" && <CgArrowLongDown />}
            </div>
          </>
        )}

        {(userStatus === "super" || userStatus === "staff") && (
          <div
            className={css["price-eye"]}
            onClick={() => {
              setShowPrice(!showPrice);
              localStorage.setItem("showPrice", JSON.stringify(!showPrice));
            }}
          >
            <IconContext.Provider value={{ size: "1.5em" }}>
              {showPrice ? <FaRegEye /> : <FaRegEyeSlash />}
            </IconContext.Provider>
          </div>
        )}
        <div
          className={
            iconLineView === "icon"
              ? css["icon-line-btn-active"]
              : css["icon-line-btn"]
          }
          onClick={() => {
            setIconLineView("icon");
            localStorage.setItem("iconLine", JSON.stringify("icon"));
          }}
        >
          <IconContext.Provider value={{ size: "1em" }}>
            <div id="login">
              <CgMenuGridR />
            </div>
          </IconContext.Provider>
        </div>
        <div
          className={
            iconLineView === "line"
              ? css["icon-line-btn-active"]
              : css["icon-line-btn"]
          }
          onClick={() => {
            setIconLineView("line");
            localStorage.setItem("iconLine", JSON.stringify("line"));
          }}
        >
          <IconContext.Provider value={{ size: "1em" }}>
            <div id="login">
              <CgMenu />
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </>
  );
};

export default ProductsList;
